<template>
  <div
    style="height: 100%; background: #fff; overflow: scroll"
    ref="information"
    class="information"
    id="scroll"
    :class="{ right_content: language == 'ar' }"
  >
    <van-sticky>
      <van-nav-bar
        class="header"
        :title="stringList.information"
        @click-left="onClickLeft"
        :class="{ title_mini_font: language == 'de' }"
      >
        <template #left>
          <img
            src="../assets/img/back_new.png"
            style="width: 20px; height: 20px"
          />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="container" v-if="!showNew">
      <div class="form-title">
        <span>{{ stringList.information_title }}</span>
      </div>
      <div v-for="(item, index) in groupList" :key="index" class="keywords">
        <div
          class="keywords_title"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span>{{ item[language] }}</span>
        </div>
        <div
          style="
            text-align: left;
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
          "
        >
          <template v-for="item1 in requestList">
            <div
              :key="item1.id"
              v-if="item1.qrDisplayGroup && item1.qrDisplayGroup == item.en"
              class="keywords_item"
              :class="{ right_keywords_item: language == 'ar' }"
            >
              <u @click="submit(item1)" v-if="language == 'en'">{{
                item1.keyword
              }}</u>
              <u @click="submit(item1)" v-else>{{ item1[language] }}</u>
            </div>
          </template>
        </div>
      </div>
      <div class="select" ref="select" id="bbb">
        <div
          class="select-title"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span>{{ stringList.information_select }}</span>
        </div>
        <div class="other-select">
          <a-select
            show-search
            size="large"
            class="select-other"
            :placeholder="stringList.information_select"
            :getPopupContainer="() => $refs.information"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="handleChange"
            :value="selectOtherRequest.keyword"
            :notFoundContent="stringList.noData"
            @focus="scrollBottom"
            @blur="abc"
          >
            <a-select-option
              v-for="item in otherRquestList"
              :key="item.id"
              :value="item.keyword"
            >
              <span v-if="language == 'en'">{{ item.keyword }}</span>
              <span v-else>{{ item[language] }}</span>
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <div class="container" style="height:calc(100% - 155px) !important;" v-else>
      <div class="form-title">
        <span>{{ stringList.new_request }}</span>
      </div>
      <div class="form">
        <div class="form-item required">
          <van-field
            v-if="language == 'en'"
            v-model="selectRequest.keyword"
            readonly
          ></van-field>
          <van-field
            v-else
            v-model="selectRequest[language]"
            readonly
          ></van-field>
        </div>
        <div class="form-item required">
          <a-select
            show-search
            size="large"
            class="roomNum"
            :placeholder="stringList.room_placeholder"
            :getPopupContainer="() => $refs.information"
            :default-active-first-option="false"
            :filter-option="false"
            :not-found-content="null"
            @search="roomSearch"
            @change="roomChange"
            :value="roomNumber"
            @focus="clearRoomNumber"
            dropdownClassName="roomNumber"
            :notFoundContent="notFoundContent"
          >
            <template #suffixIcon>
              <img
                style="width: 15px"
                src="@/assets/img/hotel-please-clean.png"
              />
            </template>
            <a-select-option
              v-for="item in roomArray"
              :key="item.id"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <van-row>
          <van-col span="11">
            <div class="form-item required">
              <van-field
                readonly
                :placeholder="stringList.today"
                :value="date"
                @click="deteSelect"
                :class="{ placeholderColor: date == '' }"
              >
                <template #right-icon>
                  <img
                    src="@/assets/img/calendar-line.png"
                    style="height: 15px"
                  />
                </template>
              </van-field>
            </div>
          </van-col>
          <van-col span="2"><div></div></van-col>
          <van-col span="11">
            <div class="form-item required">
              <van-field
                readonly
                :value="time"
                @click="selectTime"
                :placeholder="stringList.asap"
                :class="{ placeholderColor: time == '' }"
              >
                <template #right-icon>
                  <img src="@/assets/img/clock-time.png" style="height: 15px" />
                </template>
              </van-field>
            </div>
          </van-col>
        </van-row>
        <div class="form-item">
          <van-field
            v-model="notes"
            style="height: auto"
            type="textarea"
            rows="3"
            maxlength="200"
            :placeholder="stringList.comments_placeholder"
          />
        </div>

        <div class="form-item" v-if="showPhone != 0">
          <span
            class="text"
            style="font-size: 15px"
            :class="{ right_text_item: language == 'ar' }"
          >
            <span
              :class="{ right_text_item: language == 'ar' }"
              style="display: block"
              >{{showPhone === 1 ? stringList.optional1 : stringList.optional3 }}&nbsp;&nbsp;{{ showPhone === 1 ?
                stringList.optional2 : stringList.optional5
              }}</span
            >
          </span>
        </div>
        <div :class="['form-item', { 'required': showPhone === 2 }]" v-if="showPhone != 0">
          <van-field
            v-model="phone"
            type="tel"
            :placeholder="stringList.mobile_number"
            maxlength="12"
            @input="validateMobile"
          ></van-field>
        </div>
      </div>
    </div>
    <div class="bottom" v-if="showNew">
      <van-button @click="createOrder2()" class="btn">{{
        stringList.submit
      }}</van-button>
    </div>
    <van-popup
      v-model="thankPop"
      style="width: 300px"
      round
      :close-on-click-overlay="true"
      :get-container="() => $refs.information"
    >
      <div class="pop">
        <div class="pop-title" style="margin-bottom: 25px">
          <span class="title" v-if="language == 'en'">{{
            keyword.keyword
          }}</span>
          <span
            class="title"
            v-else
            :class="{ right_keywords_title: language == 'ar' }"
            >{{ keyword[language] }}</span
          >
        </div>
        <div class="pop-info">
          <!-- <div class="text">Your request has been received.</div>
          <div>
            <template v-for="item in requestList">
              <div class="info-item" v-if="item.num > 0" :key="item.id">
                <span>({{ item.num }}) {{ item.keyword }}</span>
              </div>
            </template>
          </div> -->
          <div class="text" :class="{ right_keywords_item: language == 'ar' }">
            {{ response }}
          </div>
          <template v-for="(file, index) in fileList">
            <div
              :key="index"
              v-if="showPdf(keyword.keyword, file)"
              @click="pdfView(file.fileUrl, file.title)"
              class="text"
              style="text-decoration: underline"
            >
              {{ JSON.parse(file.title2)[language] }}
            </div>
          </template>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => (thankPop = false)">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="() => (thankPop = false,confirmationPop = false)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>
        <!-- 特殊流程弹窗 -->
        <van-popup
      v-model="confirmationPop"
      round
      position="bottom"
      :close-on-click-overlay="false"
      :get-container="() => $refs.information"
    >
      <div class="pop">
        <div class="pop-title" style="margin-bottom: 20px">
          <span class="title">{{ stringList.confirmation }}</span>
        </div>
        <div class="pop-info" style="padding: 0 40px">
          <div
            class="text"
            style="font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ confirmation1 }}
          </div>
          <div
            class="text"
            style="margin-bottom: 20px; font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ confirmation }}
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <van-radio-group
              v-model="fdAction"
              icon-size="14"
              direction="horizontal"
              style="
                justify-content: space-between;
                width: 150px;
                margin-bottom: 10px;
              "
            >
              <van-radio shape="square" name="Yes">
                <span>{{ stringList.yes }}</span></van-radio
              >
              <van-radio shape="square" name="No">
                <span>{{ stringList.no }}</span></van-radio
              >
            </van-radio-group>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="confirmationPopOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="confirmationPopOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>
        <!-- TransferToFD或者TransferToRS yes分支弹窗 -->
        <van-popup
      v-model="thankPop2"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.information"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.thank }}</span>
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            <!-- {{ okContent }} -->
            {{ stringList.responseContent4 }}
            <span v-if="language == 'en'">{{ selectRequest.keyword }}.</span>
            <span v-else>{{ selectRequest[language] }}.</span>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => $router.go(-1)"> -->
          <!-- <div class="btn" @click="onClickLeft">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="onClickLeft" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>
        <!--  日历控件  -->
        <van-calendar
      v-model="showDate"
      :color="primaryColor"
      :show-title="false"
      :min-date="getMinDate()"
      @confirm="dateConfirm"
      :default-date="getDefaultDate()"
    />

    <!-- 时间选择 -->
    <van-popup v-model="showTime" position="bottom">
      <van-picker
        :columns="timeColumns"
        show-toolbar
        @confirm="timeConfirm"
        @cancel="showTime = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment-timezone";
import { postAction, getAction } from "@/api/manage";
import { Toast } from "vant";
import { encryptedDES } from "@/utils/DES.js";

export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no",
      },
    ],
  },
  name: "Information",
  data() {
    return {
      selectOtherRequest: {
        keyword: undefined,
      },
      otherRquestList: [],
      requestList: [],
      groupList: [],
      url: {
        createOrder: "/api/common/hotel/data/qrorder/create",
        login: "/api/common/user/hotel_dept_name/login",
        hotelQuery: "/api/sys_admin/hotel/query",
        hotelinfo: "/adminApi/hotelinfo/infobyhotel",
        getPageInfo: "/adminApi/file/page",
        queryRoomNum: "/api/sys_admin/hotel/room/query2/",
        getKeyWord: "/api/app/current_user/keywordsalldept/",
        getReplyList: "/adminApi/reply/listbyhotel",
        getIsInputPhone: "/adminApi/textconfig/list",
        getFileList: "/adminApi/file/filelist",
        getLanguageList: "/api/common/hotel/data/dict/by_key/languageQr",
        about: "/adminApi/test/qr/about1",
      },
      submiting: false,
      keyword: {},
      thankPop: false,
      response: "",
      fileList: Vue.ls.get("fileList"),
      language: Vue.ls.get("language"),
      languageList: Vue.ls.get("languageList"),
      stringList: {},
      confirmationPop: false,
      fdAction: "",
      confirmation:"",
      confirmation1:"",
      selectRequest:{},
      showNew:false,
      roomArray: [],
      notFoundContent: "",
      roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : undefined,
      notes: "",
      date: "",
      showDate: false,
      time: "",
      showTime: false,
      timeDisabled: true, //时间不可选
      timeColumns: [],
      phone: "",
      thankPop2: false,
    };
  },
  watch: {
    informationRequest() {
      this.requestList = this.informationRequest.filter((item) => {
        return item.serviceType == "Information";
      });
      this.otherRquestList = this.requestList;
      this.getGroup();
    },
  },
  computed: {
    ...mapGetters({
      informationRequest: "informationRequest",
      roomList: "roomList",
      showPhone: "showPhone",
      primaryColor: "primaryColor",
      amcniticsRequest: "amcniticsRequest",
    }),
  },
  mounted() {
    for (let element of document.getElementsByClassName("color")) {
      element.style.color = this.primaryColor;
    }
    this.$forceUpdate();
  },
  async created() {
    //无痕浏览器直接进入逻辑
    let tsp = this.$route.query.tsp;
    let now = new Date().getTime();
    console.log(now - tsp * 1000);
    if (now - this.tsp * 1000 > 1000 * 60 * 60 * 24) {
      //如果是走了这个about 后台调用一下接口 打log
      getAction(this.url.about).then((res) => {});
      this.$router.push({ name: "About" });
    } else {
      if (!Vue.ls.get("HTTOKEN")) {
        let params = {
          hotelDeptName: "qrbot",
          hotelName: this.$route.query.inno,
          password: "123456",
        };
        postAction(this.url.login, params).then((res) => {
          if (res.status == 0) {
            this.token = res.data.token;
            Vue.ls.set("HTTOKEN", res.data.token);
            getAction(this.url.getLanguageList).then((res) => {
              Vue.ls.set("languageList", res.data);
            });
            postAction(this.url.hotelQuery, {
              hotelNo: this.$route.query.inno,
            }).then((res) => {
              if (res.status == 0) {
                Vue.ls.set("timeZone", res.data.timeZone, 1000 * 60 * 60 * 24);
                Vue.ls.set("hotelId", res.data.id, 1000 * 60 * 60 * 24);
                //判断是否启动夜间模式
                Vue.ls.set(
                  "overNightHoursInfo",
                  res.data.overNightHours,
                  1000 * 60 * 60 * 24
                );
                // if (
                //   res.data.overNightHours != "disable" &&
                //   res.data.overNightHours != "Regular Operating Hour"
                // ) {
                //   Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                // } else {
                //   Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                // }
                if (
                  res.data.overNightHours == "disable" ||
                  res.data.overNightHours == "Regular Operating Hour"
                ) {
                  Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                } else if (
                  res.data.overNightHours == "Cycle Regular Operating Hour"
                ) {
                  // Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                  let now = new Date();
                  let taody = moment(now)
                    .tz(res.data.timeZone)
                    .format("YYYY/MM/DD");
                  let startTime = moment.tz(
                    moment(taody + " " + res.data.fdStartTime).format(
                      "YYYY-MM-DD HH:mm"
                    ),
                    res.data.timeZone
                  );
                  let endTime = moment.tz(
                    moment(
                      res.data.fdEndTime.replace("tomorrow_", taody + " ")
                    ).format("YYYY-MM-DD HH:mm"),
                    res.data.timeZone
                  );
                  if (this.getIsInTimeRange(startTime, endTime)) {
                    Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                  } else {
                    Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                  }
                } else {
                  Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                }
                Vue.ls.set(
                  "fdStartTime",
                  res.data.fdStartTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "fdEndTime",
                  res.data.fdEndTime,
                  1000 * 60 * 60 * 24
                );

                Vue.ls.set("engHour", res.data.engHour, 1000 * 60 * 60 * 24);
                Vue.ls.set(
                  "engEndTime",
                  res.data.engEndTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "engStartTime",
                  res.data.engStartTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "engRequestTime",
                  res.data.engRequestTime,
                  1000 * 60 * 60 * 24
                );
                getAction(this.url.hotelinfo, { hotelId: res.data.id }).then(
                  (r) => {
                    if (r.status == 0) {
                      this.editColor("#" + r.data.color);
                    }
                  }
                );
                postAction(this.url.getFileList, { hotelId: res.data.id }).then(
                  (r) => {
                    if (r.status == 0) {
                      Vue.ls.set(
                        "fileList",
                        r.data.htFileList,
                        1000 * 60 * 60 * 24
                      );
                    }
                  }
                );
                let params = {
                  hotelId: Vue.ls.get("hotelId"),
                  type: "internal",
                };
                getAction(this.url.getReplyList, params).then((res) => {
                  if (res.status == 0) {
                    for (let element of res.data) {
                      if (
                        element.botTrigger == "556" ||
                        element.botTrigger == "557"
                      ) {
                        Vue.ls.set(
                          "fdOption",
                          element.botTriggerValue,
                          1000 * 60 * 60 * 24
                        );
                        Vue.ls.set(
                          "fdOptionReponse",
                          element.botResponse2,
                          1000 * 60 * 60 * 24
                        );
                        break;
                      }
                    }
                    for (let element of res.data) {
                      if (element.botTrigger == "677") {
                        Vue.ls.set(
                          "engHourReponse",
                          element.botResponse2,
                          1000 * 60 * 60 * 24
                        );
                        break;
                      }
                    }
                  }
                });
                let form = {
                  hotelId: Vue.ls.get("hotelId"),
                  size: 10,
                  current: 1,
                };
                postAction(this.url.getIsInputPhone, form).then((res) => {
                  if (res.status == 0) {
                    res.data.records.forEach((item) => {
                      if (Vue.ls.get("type") == "guest") {
                        if (item.requestType == "Guest Room Request") {
                          if (item.textResponse == "Disable") {
                            this.editShowPhone(0);
                            return;
                          }
                          if (item.textResponse == "Enable(Optional)") {
                            this.editShowPhone(1);
                            return;
                          }
                          if (item.textResponse == "Enable(Required)") {
                            this.editShowPhone(2);
                            return;
                          }
                        }
                      } else if (Vue.ls.get("type") == "nonguest") {
                        if (item.requestType == "Meeting Room Request") {
                          if (item.textResponse == "Disable") {
                            this.editShowPhone(0);
                            return;
                          }
                          if (item.textResponse == "Enable(Optional)") {
                            this.editShowPhone(1);
                            return;
                          }
                          if (item.textResponse == "Enable(Required)") {
                            this.editShowPhone(2);
                            return;
                          }
                        }
                      }
                    });
                  }
                });
              }
            });
            if (this.amcniticsRequest.length == 0) {
              getAction(`${this.url.getKeyWord}${"fdkey"}`).then((res) => {
                if (res.status == 0) {
                  this.editAmcniticsRequest(res.data);
                }
              });
            }
            if (this.informationRequest.length == 0) {
              getAction(`${this.url.getKeyWord}${"nonfdkey"}`).then((res) => {
                if (res.status == 0) {
                  this.editInformationRequest(res.data);
                }
              });
            }
            if (this.$route.query.type) {
              Vue.ls.set("type", this.$route.query.type, 1000 * 60 * 60 * 24);
              if (Vue.ls.get("type") == "guest") {
                getAction(
                  `${this.url.queryRoomNum}${"guest"}/${"allmeeting"}`
                ).then((res) => {
                  if (res.status == 0) {
                    this.editRoomList(res.data);
                  }
                });
              } else if (Vue.ls.get("type") == "nonguest") {
                getAction(
                  `${
                    this.url.queryRoomNum
                  }${"guMeeting Roomest"}/${"allmeeting"}`
                ).then((res) => {
                  if (res.status == 0) {
                    this.editRoomList(res.data);
                  }
                });
              }
            }
            if (this.$route.query.room) {
              Vue.ls.set("room", this.$route.query.room, 1000 * 60 * 60 * 24);
            }
            if (this.$route.query.inno) {
              Vue.ls.set("inno", this.$route.query.inno, 1000 * 60 * 60 * 24);
            }
          }
        });
        if (!Vue.ls.get("language")) {
          Vue.ls.set("language", "en");
          this.language = "en";
        }
        this.requestList = this.informationRequest.filter((item) => {
          return item.serviceType == "Information";
        });
        this.otherRquestList = this.requestList;
        this.getGroup();
        let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
        this.stringList = await import("@/data/" + languageJsonName);
      } else {
        this.requestList = this.informationRequest.filter((item) => {
          return item.serviceType == "Information";
        });
        this.otherRquestList = this.requestList;
        this.getGroup();
        let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
        this.stringList = await import("@/data/" + languageJsonName);
      }
    }
    let hour = [];
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            hour.push("0" + i);
          } else {
            hour.push("" + i);
          }
        }
        this.timeColumns.push({
          values: hour,
        });
        let minte = [];
        for (let i = 0; i <= 59; i++) {
          if (i < 10) {
            minte.push("0" + i);
          } else {
            minte.push("" + i);
          }
        }
        this.timeColumns.push({
          values: minte,
        });
        this.timeColumns.push({
          values: ["AM", "PM"],
        });
  },
  methods: {
    moment,
    ...mapActions([
      "editColor",
      "editAmcniticsRequest",
      "editInformationRequest",
      "editShowPhone",
      "editRoomList",
    ]),
    abc() {
      let div = document.getElementById("scroll");
      div.style.height = "100%";
    },
    scrollBottom() {
      let div = document.getElementById("scroll");
      div.scrollTop = div.scrollHeight;
    },
    pdfView(inno, title) {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      let urlType = inno.split(".")[inno.split(".").length - 1];
      //创建订单
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let time = new Date().valueOf().toString();
      let params = {
        createTime: createTime.utc().format(),
        fdaction: "",
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelId: Vue.ls.get("hotelId"),
        hotelDeptId: "",
        inquiryNum: "",
        notes: "",
        requestType: Vue.ls.get("type"),
        roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : "GSD",
        requestTime: createTime.utc().format(),
        serviceKey: title ? title : "Guest Service Directory",
        serviceType: "gsd",
        createTimeValue: time,
        tid: encryptedDES(time.substring(time.length - 8)),
        language: this.language,
      };

      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }

      if (
        urlType == "xlsx" ||
        urlType == "docx" ||
        urlType == "pptx" ||
        urlType == "xls" ||
        urlType == "doc" ||
        urlType == "ppt"
      ) {
        window.location.href =
          "https://view.officeapps.live.com/op/view.aspx?src=" + inno;
      } else if (
        urlType == "jpg" ||
        urlType == "png" ||
        urlType == "jpeg" ||
        urlType == "gif"
      ) {
        window.location.href = inno;
      } else if (urlType == "pdf") {
        this.$router.push({
          name: "PdfView",
          query: { inno: inno },
        });
      } else {
        window.location.href = inno;
      }
    },
    showPdf(keyword, file) {
      if (!keyword) return false;
      let services = file.service;
      for (let ss of services.split(",")) {
        console.log(ss);
        if (
          keyword.toLowerCase() == ss.toLowerCase() &&
          file.enable != null &&
          file.enable
        ) {
          return true;
        }
      }
      return false;
    },
    onClickLeft() {
      // this.$router.go(-1);
      let query = {};
      query.type = this.$route.query.type;
      query.inno = this.$route.query.inno;
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      if (this.$route.query.building) {
        query.building = this.$route.query.building;
      }     
      query.tsp = this.$route.query.tsp;
      this.$router.replace({ name: "Home", query: query });
    },
    getIsInTimeRange(startTime, endTime) {
      let now = new Date();
      if (startTime.valueOf() < endTime.valueOf()) {
        return (
          startTime.valueOf() <= now.getTime() &&
          now.getTime() <= endTime.valueOf()
        );
      } else {
        return (
          startTime.valueOf() <= now.getTime() ||
          now.getTime() <= endTime.valueOf()
        );
      }
    },
    handleSearch(value) {
      // let div = document.getElementById("scroll");
      // div.scrollTop = 1000;
      let divv = document.getElementById("scroll");
      divv.style.height = "200%";
      let div = document.getElementById("bbb");
      div.scrollIntoView({ block: "center" });
      if (this.language == "en") {
        this.otherRquestList = this.requestList.filter((item) => {
          return item.keyword.toLowerCase().indexOf(value.toLowerCase()) != -1;
        });
      } else {
        this.otherRquestList = this.requestList.filter((item) => {
          return (
            item[this.language].toLowerCase().indexOf(value.toLowerCase()) != -1
          );
        });
      }
    },
    handleChange(value) {
      // let div = document.getElementById("scroll");
      // div.scrollTop = 1000;
      let div = document.getElementById("bbb");
      div.scrollIntoView({ block: "center" });
      this.selectOtherRequest.keyword = value;

      this.otherRquestList = this.requestList.filter((item) => {
        return item.keyword.toLowerCase().indexOf(value.toLowerCase()) != -1;
      });

      for (let element of this.otherRquestList) {
        if (element.keyword == value) {
          this.submit(element);
        }
      }
    },
    getGroup() {
      for (let element of this.requestList) {
        if (element.qrDisplayGroup) {
          let flag = false;
          for (let group of this.groupList) {
            if (element.qrDisplayGroup == group.en) {
              flag = true;
            }
          }
          if (!flag) {
            let group = {};
            for (let delement of this.languageList) {
              if (delement.value == "en") {
                group[delement.value] = element["qrDisplayGroup"];
              } else {
                group[delement.value] =
                  element[
                    "qrDisplayGroup" +
                      delement.value.slice(0, 1).toUpperCase() +
                      delement.value.slice(1).toLowerCase()
                  ];
              }
            }
            this.groupList.push(group);
          }
        }
      }
    },
    validateMobile(val) {
      //美式mobile 号码格式，3-3-4格式
      //代码参照https://github.com/wuhao000/antd-mobile-vue/blob/master/src/packages/input/src/index.tsx
      let ctrlValue = val;
      ctrlValue = val.replace(/\D/g, "").substring(0, 11);
      const valueLen = ctrlValue.length;
      if (valueLen > 3 && valueLen < 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(3)}`;
      } else if (valueLen >= 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(
          3,
          3
        )}-${ctrlValue.substr(6)}`;
      }
      this.phone = ctrlValue;
      // this.$set(this.requestForm, "phone", ctrlValue);
    },
    dateConfirm(date) {
      // console.log(moment(date.getTime()).format("MM/DD/YYYY"));
      this.date = moment(date.getTime()).format("MM/DD/YYYY");
      // 判断是否为今天
      if (
        this.date !=
        moment(new Date().getTime())
          .tz(Vue.ls.get("timeZone"))
          .format("MM/DD/YYYY")
      ) {
        this.time = "10:00 AM";
      } else {
        this.time = this.stringList.asap;
      }
      this.showDate = false;
    },
    timeConfirm(value) {
      this.showTime = false;
      this.timePickerData = value;
      this.time = value[0] + ":" + value[1] + " " + value[2];
    },
    selectTime() {
      if (this.timeDisabled) {
        this.showTime = true;
        let time = moment(new Date())
          .tz(Vue.ls.get("timeZone"))
          .format("hh:mm A");
        this.timeColumns[0].defaultIndex = Number(time.slice(0, 2)) - 1;
        this.timeColumns[1].defaultIndex = Number(time.slice(3, 5));
        this.timeColumns[2].defaultIndex =
          time.slice(time.length - 2, time.length) == "AM" ? 0 : 1;
      }
    },
    getDefaultDate() {
      let date = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      // console.log(date);
      return new Date(date);
    },
    getMinDate() {
      let date = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      // console.log(date);
      return new Date(date);
    },
    deteSelect() {
      if (this.timeDisabled) {
        this.showDate = true;
      }
    },
    clearRoomNumber() {
      if (this.roomNumber == undefined || this.roomNumber == "") {
        this.roomArray = this.roomList;
      } else {
        this.roomNumber = "";
        this.roomArray = [];
        this.notFoundContent = "";
        this.roomArray = this.roomList;
      }
    },
    roomSearch(value) {
      if (value) {
        this.roomArray = this.roomList.filter((item) => {
          return item.name.toLowerCase().indexOf(value.toLowerCase()) != -1;
        });
        this.notFoundContent = this.stringList.invalid_room;
      } else {
        this.roomArray = [];
        this.notFoundContent = "";
      }
      if (this.roomArray.length == 1) {
        this.roomNumber = this.roomArray[0].name;
        document.querySelector(":focus").blur();
      }
    },
    roomChange(value) {
      this.roomNumber = value;
      this.roomArray = this.roomList.filter((item) => {
        return item.name.indexOf(value) != -1;
      });
      if (this.roomArray.length == 1) {
        this.roomNumber = this.roomArray[0].name;
        document.querySelector(":focus").blur();
      }
    },
    createOrder(request){
      let div = document.getElementById("bbb");
      div.scrollIntoView();
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let time = new Date().valueOf().toString();
      let param = {
        createTime: createTime.utc().format(),
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelDeptId: request.hotelDeptId,
        inquiryNum: "",
        notes: "",
        requestType: Vue.ls.get("type"),
        roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : "None",
        requestTime: createTime.utc().format(),
        serviceKey: request.keyword,
        serviceType: request.serviceType,
        createTimeValue: time,
        tid: encryptedDES(time.substring(time.length - 8)),
        language: this.language,
      };
      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, param)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
              this.keyword = request;
              this.thankPop = true;
              this.response = res.msg;
              this.otherRquestList = this.requestList;
              this.selectOtherRequest = {
                keyword: undefined,
              };
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }
    },
    createOrder2() {
      if (this.fdAction == "Yes" || this.fdAction == "") {
        if (!this.roomNumber && this.selectRequest.section != "Answer") {
          Toast(this.stringList.room_toast);
          return;
        }
      }

      if (!this.selectRequest.keyword) {
        Toast(this.stringList.service_toast);
        return;
      }

      if (this.selectRequest.section == 'AnswerTicket'){
          this.answers = JSON.parse(this.selectRequest.response)[this.language];
      }
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let today = moment(now).tz(Vue.ls.get("timeZone")).format("MM/DD/YY");
      let tomorrow = moment(now.getTime() + 1000 * 60 * 60 * 24)
        .tz(Vue.ls.get("timeZone"))
        .format("MM/DD/YY");

      //确定requestTime
      let date = this.date;
      if (!date) {
        date = moment(new Date())
          .tz(Vue.ls.get("timeZone"))
          .format("YYYY/MM/DD");
      }
      let time = this.time;
      if (!time || time == this.stringList.asap) {
        time = moment(new Date()).tz(Vue.ls.get("timeZone")).format("hh:mm A");
      }
      let requestTime = moment.tz(
        moment(date + " " + time).format("YYYY-MM-DD HH:mm"),
        Vue.ls.get("timeZone")
      );

      if (requestTime.valueOf() + 180000 < now.getTime()) {
        Toast(this.stringList.time_toast);
        return;
      }
      if (this.notes.length > 200 && this.notes.length != 0) {
        debugger;
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;"><span>` +
            this.stringList.limit_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }
      if (this.showPhone == 2){
        if (!this.phone) {
            Toast(this.stringList.optional4);
            return;
          }
      }
      if (this.phone && this.phone.length !== 12) {
        Toast(this.stringList.phone_toast);
        return;
      }

      if (!this.date && !this.time) {
        this.tips = this.stringList.tipsContent1;
      } else {
        if (requestTime.format("MM/DD/YY") == today) {
          this.tips =
            this.stringList.tipsContent2 + requestTime.format("hh:mm A") + ".";
        } else if (requestTime.format("MM/DD/YY") == tomorrow) {
          this.tips =
            this.stringList.tipsContent3 + requestTime.format("hh:mm A") + ".";
        } else {
          this.tips =
            this.stringList.tipsContent4 +
            requestTime.format("MM/DD/YY hh:mm A") +
            ".";
        }
      }
      let time1 = new Date().valueOf().toString();
      let params = {
        hotelId: Vue.ls.get("hotelId"),
        fdaction: this.fdAction,
        createTime: createTime.utc().format(),
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelDeptId: this.selectRequest.hotelDeptId,
        inquiryNum: "",
        notes: this.notes,
        requestType: Vue.ls.get("type"),
        roomNumber: this.roomNumber ? this.roomNumber : "None",
        requestTime: requestTime.utc().format(),
        serviceKey: this.selectRequest.keyword,
        serviceType: this.selectRequest.serviceType,
        phone: this.phone,
        createTimeValue: time1,
        tid: encryptedDES(time1.substring(time1.length - 8)),
        language: this.language,
      };
      if (
        Vue.ls.get("overNightHoursInfo") == "Front Desk Hour" ||
        Vue.ls.get("overNightHoursInfo") == "Cycle Front Desk Hour"
      ) {
        params.fdhour = Vue.ls.get("fdOption");
      } else if (
        Vue.ls.get("overNightHoursInfo") == "Cycle Regular Operating Hour"
      ) {
        params.fdhour = "Regular Operating Hour";
      }
      if (this.fdAction == "No") {
        params.status = "Complete-Bot";
      }

      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
                  this.thankPop2 = true;
                this.okContent = res.msg;
            } else {
              this.submiting = false;
              Toast(res.msg);
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }
    },
    submit(request) {
      console.log(request)
      this.selectRequest = request
      if(request.section=="TransferToFD"){
        this.fdAction = "No";
        this.confirmationPop = true
        this.confirmation = this.stringList.confirmationContent;
        this.confirmation1 = JSON.parse(request.response)[
            this.language
          ];
      }else{
        this.createOrder(request)
      }
    },
    confirmationPopOK() {
      if (this.fdAction == "No") {
        this.createOrder(this.selectRequest);
      } else {
        this.confirmationPop = false;
        this.showNew = true
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right_keywords_title {
  display: grid !important;
  text-align: right !important;
}
.right_keywords_item {
  text-align: right !important;
}
.right_content {
  /deep/.ant-select-selection__placeholder {
    text-align: right !important;
  }
  /deep/.van-field__control {
    text-align: right !important;
  }
  /deep/.ant-select {
    text-align: right !important;
    direction: rtl;
  }
  /deep/.ant-select-dropdown-menu-item {
    text-align: right !important;
  }
}
.right_text_item {
  text-align: right !important;
}
.header {
  /deep/.van-nav-bar__content {
    height: 50px;
    background-color: #f3f3f3;
    .van-nav-bar__title {
      font-size: 18px;
      line-height: 20px;
      font-family: $fontFamily;
      color: #4f4f4f;
      font-weight: 400;
    }
    .van-nav-bar__arrow {
      color: #bbbbbb;
      font-size: 22px;
    }
  }
}

.bottom {
  height: 104px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  .btn {
    height: 47px;
    width: 167px;
    border-radius: 20px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    span {
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
      color: #ffffff;
    }
  }
}

.container::-webkit-scrollbar {
  display: none;
}

.container {
  height: calc(100% - 155px);
  background-color: #fff;
  width: 100%;
  padding: 20px 30px;
  overflow-y: auto;

  .form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    word-break: normal;
    position: relative;
    margin-bottom: $form-title-margin-bottom;

    span {
      color: #333333;
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
    }
  }

  .form {
    padding-left: 10px;
    word-break: normal;

    .required {
      position: relative;

      &:before {
        position: absolute;
        content: "*";
        left: -15px;
        width: 15px;
        height: 40px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-item {
      font-size: 15px;
      word-break: normal;
      text-align: left;
      margin-bottom: 20px;
      line-height: 17px;
      font-family: $fontFamily;

      .van-field {
        min-height: 40px;
        border-radius: 6px;
        color: #9a9a9a;
        background-color: #f3f3f3;
        width: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        height: 100%;
        font-family: $fontFamily;
        line-height: 17px;
        font-size: 15px;

        ::-webkit-input-placeholder {
          color: #9a9a9a !important;
        }
      }
      .placeholderColor {
        ::-webkit-input-placeholder {
          color: #323233 !important;
        }
      }

      .text {
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #4f4f4f;
      }

      .roomNum {
        width: 100%;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #323233;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }

      /deep/textarea {
        padding: 10px 0 !important;
      }
    }

    .form-item2 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        width: 215px;
      }

      .van-stepper {
        display: flex;
        flex-flow: nowrap;
      }

      /deep/.van-stepper--round .van-stepper__minus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }

      /deep/.van-stepper--round .van-stepper__plus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }
    }

    .form-item2:last-child {
      margin-bottom: 0px;
    }

    .form-item3 {
      height: 40px;
      text-align: left;
      display: flex;
      align-items: center;

      span {
        line-height: 16px;
        font-size: 14px;
        word-break: normal;
        font-family: $fontFamily;
        color: #1e4380;
      }
    }
  }
}

/deep/.van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.van-popup--bottom.van-popup--round {
  border-radius: 12px 12px 0 0;
}

.van-popup--center.van-popup--round {
  border-radius: 6px;
}

.pop {
  word-break: normal;
  text-align: left;
  max-height: 80vh;

  .pop-title {
    margin: 0 30px;
    height: 60px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 18px;
      line-height: 20px;
      color: #1e4380;
      font-weight: 600;
    }

    .icon {
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      left: 0px;
    }

    .shopping {
      font-size: 30px;
      color: #bbbbbb;
      position: absolute;
      right: 0px;
    }

    .van-badge__wrapper {
      display: flex;
    }

    /deep/.van-badge--fixed {
      top: 5px;
      right: 5px;
    }
  }

  .pop-title3 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    // justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-title2 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-container {
    padding: 0 30px;
    max-height: calc(80vh - 160px);
    overflow-y: auto;

    .pop-item {
      // height: $pop-item-height;
      min-height: 50px;
      padding: 10px 0;
      border-top: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 22px;
        margin-right: 25px;
      }
      .text {
        flex: 1;
        color: #000000;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        margin-right: 10px;
      }
      .icon {
        font-size: 18px;
        color: #bbbbbb;
      }

      .select {
        background-color: $primary-color;
        border: 1px solid $primary-color !important;
        color: #ffffff !important;
      }

      .btn {
        height: 25px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 1px solid #bbbbbb;
        color: #4f4f4f;

        span {
          font-size: 14px;
          line-height: 16px;
          font-family: $fontFamily;
        }
      }

      &:first-child {
        border-top: 0px;
      }
    }

    .other-select {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 30px;
      padding-left: 50px;

      .select-other {
        width: 100%;
        // margin-left: 50px;
        font-size: 15px;
        font-family: $fontFamily;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }
    }

    .van-stepper {
      display: flex;
      flex-flow: nowrap;
    }

    /deep/.van-stepper--round .van-stepper__minus {
      background-color: #ffffff;
      color: #1e4380;
      border: 1px solid #1e4380;
    }

    /deep/.van-stepper--round .van-stepper__plus {
      background-color: #ffffff;
      color: #1e4380;
      border: 1px solid #1e4380;
    }
  }

  .btn-area {
    height: 100px;
    border-top: 2px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 47px;
      width: 167px;
      border-radius: 20px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        line-height: 18px;
        font-family: $fontFamily;
        color: #ffffff;
      }
    }
  }

  .pop-info {
    padding: 0 40px;
    .text {
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      font-family: $fontFamily;
      margin-bottom: 10px;
    }

    .info-item {
      padding-left: 20px;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      font-family: $fontFamily;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000;
        left: 0px;
        top: 7px;
      }
    }

    .info-item:last-child {
      margin-bottom: 20px;
    }

    /deep/.van-radio__label {
      font-size: 15px;
      line-height: 17px;
      color: #333333;
      font-family: $fontFamily;
    }

    /deep/.van-radio__icon--checked .van-icon {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

/deep/.ant-select-dropdown {
  z-index: 9999;
  text-align: left;
}
/deep/.ant-select-dropdown-menu-item {
  font-size: 17px;
  color: #000000;
  line-height: 24px;
  font-family: $fontFamily;
}
/deep/.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-selected {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/ .roomNumber .ant-select-dropdown-menu {
  max-height: 250px !important;
}

/deep/.otherSelect {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/deep/.otherSelect .ant-select-dropdown-menu {
  max-height: 200px !important;
}
/deep/.ant-select-dropdown-menu-item-disabled {
  color: #bbbbbb;
}
/deep/.van-field__body {
  line-height: 20px;
}

///
.right_keywords_title {
  display: block !important;
  text-align: right !important;
}
.right_keywords_item {
  text-align: right !important;
}
.right_content {
  /deep/.ant-select-selection__placeholder {
    text-align: right !important;
  }
  /deep/.van-field__control {
    text-align: right !important;
    direction: rtl;
  }
  /deep/.ant-select {
    text-align: right !important;
    direction: rtl;
  }
  /deep/.ant-select-dropdown-menu-item {
    text-align: right !important;
  }
}
.information {
  font-family: $fontFamily;
}
/deep/.ant-select-dropdown-menu-item {
  font-size: 17px;
  color: #000000;
  line-height: 24px;
  font-family: $fontFamily;
}
/deep/.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-selected {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-disabled {
  color: #bbbbbb;
}
.title_mini_font {
  /deep/ .van-nav-bar__title {
    font-size: 13px !important;
  }
}
.header {
  /deep/.van-nav-bar__content {
    height: 50px;
    background-color: #f3f3f3;
    .van-nav-bar__title {
      font-size: 18px;
      line-height: 20px;
      font-family: $fontFamily;
      color: #4f4f4f;
      font-weight: 400;
    }
    .van-nav-bar__arrow {
      color: #bbbbbb;
      font-size: 22px;
    }
  }
}
/deep/ .ant-select-dropdown-menu {
  max-height: 250px !important;
}
.select {
  .select-title {
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: start;
    height: 50px;
    word-break: normal;
    position: relative;
    // margin-bottom: 20px;
    font-family: $fontFamily;
    color: #000000;

    // &:after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 2px;
    //   background-color: #e8e8e8;
    // }
  }
}
.keywords {
  font-size: 14px;
  margin-bottom: 15px;
  // font-family: $fontFamily;
  .keywords_title {
    color: #1e4380;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: start;
    height: 30px;
    word-break: normal;
    position: relative;
    margin-bottom: 10px;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 1px;
    //   background-color: #e8e8e8;
    // }
  }
  .keywords_item {
    width: 49%;
    // display: inline-block;
    line-height: 20px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;

    &:before {
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #000;
      left: 0px;
      top: 7px;
    }
  }
}
.bottom {
  height: 104px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    height: 47px;
    width: 167px;
    border-radius: 20px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    span {
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
      color: #ffffff;
    }
  }
}

.container::-webkit-scrollbar {
  display: none;
}
.container {
  height: calc(100% - 155px);
  background-color: #fff;
  width: 100%;
  padding: 20px 30px;
  overflow-y: auto;

  .form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    word-break: normal;
    position: relative;
    margin-bottom: $form-title-margin-bottom;

    span {
      color: #333333;
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
    }
  }

  .form {
    padding-left: 10px;
    word-break: normal;

    .required {
      position: relative;

      &:before {
        position: absolute;
        content: "*";
        left: -15px;
        width: 15px;
        height: 40px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-item {
      font-size: 15px;
      word-break: normal;
      text-align: left;
      margin-bottom: 20px;
      line-height: 17px;
      font-family: $fontFamily;

      .van-field {
        min-height: 40px;
        border-radius: 6px;
        color: #9a9a9a;
        background-color: #f3f3f3;
        width: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        height: 100%;
        font-family: $fontFamily;
        line-height: 17px;
        font-size: 15px;

        ::-webkit-input-placeholder {
          color: #9a9a9a !important;
        }
      }
      .placeholderColor {
        ::-webkit-input-placeholder {
          color: #323233 !important;
        }
      }

      .text {
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #4f4f4f;
      }

      .roomNum {
        width: 100%;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #323233;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }

      /deep/textarea {
        padding: 10px 0 !important;
      }
    }

    .form-item2 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        width: 215px;
      }

      .van-stepper {
        display: flex;
        flex-flow: nowrap;
      }

      /deep/.van-stepper--round .van-stepper__minus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }

      /deep/.van-stepper--round .van-stepper__plus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }
    }

    .form-item2:last-child {
      margin-bottom: 0px;
    }

    .form-item3 {
      height: 40px;
      text-align: left;
      display: flex;
      align-items: center;

      span {
        line-height: 16px;
        font-size: 14px;
        word-break: normal;
        font-family: $fontFamily;
        color: #1e4380;
      }
    }
  }
}
.container {
  background-color: #fff;
  width: 100%;
  padding: 20px 30px;
  height: calc(100% - 50px);
  overflow: auto;

  .form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    word-break: normal;
    position: relative;
    margin-bottom: 20px;

    span {
      color: #333333;
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #e8e8e8;
    }
  }

  .form {
    padding-left: 15px;

    .required {
      position: relative;

      &:before {
        position: absolute;
        content: "*";
        left: -15px;
        width: 15px;
        height: 40px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-item {
      font-size: 14px;
      word-break: normal;
      text-align: left;
      margin-bottom: 20px;
      line-height: 16px;
      font-family: $fontFamily;

      .van-field {
        min-height: 40px;
        border-radius: 6px;
        color: #9a9a9a;
        background-color: #f3f3f3;
        width: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        height: 100%;
      }

      .text {
        font-size: 14px;
        line-height: 16px;
        font-family: $fontFamily;
        color: #4f4f4f;
      }
    }

    .form-item2 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        width: 215px;
      }

      .van-stepper {
        display: flex;
        flex-flow: nowrap;
      }

      /deep/.van-stepper--round .van-stepper__minus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }

      /deep/.van-stepper--round .van-stepper__plus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }
    }

    .form-item3 {
      height: 40px;
      text-align: left;
      display: flex;
      align-items: center;

      span {
        line-height: 16px;
        font-size: 14px;
        word-break: normal;
        font-family: $fontFamily;
        color: #1e4380;
      }
    }
  }
}

.other-select {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .select-other {
    width: 100%;
    font-size: 14px;
  }
  /deep/.ant-select-dropdown-menu {
    max-height: 160px !important;
  }
  /deep/.ant-select-selection {
    border-color: transparent;
    background-color: #f3f3f3;
    border-radius: 6px;
    box-shadow: none !important;
  }
  /deep/.ant-select-selection__placeholder {
    color: #9a9a9a;
  }
  /deep/.ant-select-selection:hover {
    border-color: transparent;
  }
}

/deep/.ant-select-dropdown {
  z-index: 9999;
  text-align: left;
}

/deep/.van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
.pop {
  word-break: normal;
  text-align: left;

  .pop-title {
    margin: 0 30px;
    padding: 40px 0px 10px;
    display: flex;
    align-items: center;
    // justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      width: 100%;
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 18px;
      color: #1e4380;
      font-weight: 600;
    }

    .icon {
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      left: 0px;
    }

    .shopping {
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      right: 0px;
    }

    .van-badge__wrapper {
      display: flex;
    }
  }
  .pop-title3 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    // justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
    }
  }

  .pop-title2 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
    }
  }

  .pop-container {
    padding: 0 30px;
    max-height: 470px;
    overflow-y: auto;

    .pop-item {
      height: $pop-item-height;
      border-top: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 24px;
        margin-right: 25px;
      }
      .text {
        flex: 1;
        color: #000000;
        font-size: 16px;
        line-height: 18px;
        font-family: $fontFamily;
      }
      .icon {
        font-size: 18px;
        color: #bbbbbb;
      }

      &:first-child {
        border-top: 0px;
      }
    }

    .other-select {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .select-other {
        width: 190px;
        margin-left: 50px;
        font-size: 16px;
      }
      /deep/ .ant-select-dropdown-menu {
        max-height: 160px !important;
      }
      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
      }
    }

    .van-stepper {
      display: flex;
      flex-flow: nowrap;
    }

    /deep/.van-stepper--round .van-stepper__minus {
      background-color: #ffffff;
      color: #1e4380;
      border: 1px solid #1e4380;
    }

    /deep/.van-stepper--round .van-stepper__plus {
      background-color: #ffffff;
      color: #1e4380;
      border: 1px solid #1e4380;
    }
  }

  .btn-area {
    height: 100px;
    border-top: 2px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 45px;
      width: 150px;
      border-radius: 20px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        line-height: 18px;
        font-family: $fontFamily;
        color: #ffffff;
      }
    }
  }

  .pop-info {
    padding: 0 30px;
    .text {
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      font-family: $fontFamily;
      margin-bottom: 20px;
    }

    .info-item {
      padding-left: 20px;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      font-family: $fontFamily;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000;
        left: 0px;
        top: 7px;
      }
    }

    .info-item:last-child {
      margin-bottom: 20px;
    }

    /deep/.van-radio__label {
      font-size: 16px;
      line-height: 18px;
      color: #333333;
      font-family: $fontFamily;
    }

    /deep/.van-radio__icon--checked .van-icon {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
</style>

